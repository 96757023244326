"use strict";
/*
 * Copyright © 2024 Aram Meem Company Limited. All Rights Reserved.
 */
/* eslint-disable require-jsdoc */
const TRANSITION_END = 'transitionend';
const CONTACT_US = 'contact-us';
const triggerTransitionEnd = (element) => {
    element === null || element === void 0 ? void 0 : element.dispatchEvent(new Event(TRANSITION_END));
};
const executeAfterTransition = (element, callback) => {
    const getTransitionDurationFromElement = () => {
        const styles = window.getComputedStyle(element);
        let { transitionDuration, transitionDelay } = styles;
        const floatTransitionDuration = Number.parseFloat(transitionDuration);
        const floatTransitionDelay = Number.parseFloat(transitionDelay);
        if (!floatTransitionDuration && !floatTransitionDelay) {
            return 0;
        }
        [transitionDuration] = transitionDuration.split(',');
        [transitionDelay] = transitionDelay.split(',');
        const transitionDurationSeconds = Number.parseFloat(transitionDuration);
        const transitionDelaySeconds = Number.parseFloat(transitionDelay);
        return (transitionDurationSeconds + transitionDelaySeconds) * 1000;
    };
    const duration = getTransitionDurationFromElement() + 5;
    let called = false;
    const handler = ({ target }) => {
        if (target !== element) {
            return;
        }
        called = true;
        element.removeEventListener(TRANSITION_END, handler);
        callback();
    };
    element.addEventListener(TRANSITION_END, handler);
    setTimeout(() => {
        if (!called) {
            triggerTransitionEnd(element);
        }
    }, duration);
};
const isHTMLElement = (element) => {
    return element instanceof HTMLElement;
};
const getParent = (element, check) => {
    let parent = element;
    while (parent) {
        if (check(parent)) {
            return parent;
        }
        parent = parent.parentElement;
    }
};
const stopPropagation = (event) => event === null || event === void 0 ? void 0 : event.stopPropagation();
const preventDefault = (event) => event === null || event === void 0 ? void 0 : event.preventDefault();
const hideAside = () => {
    var _a;
    (_a = window.visibleAside) === null || _a === void 0 ? void 0 : _a.classList.remove('visible');
    window.visibleAside = undefined;
    const body = document.querySelector('body');
    body === null || body === void 0 ? void 0 : body.classList.remove('no-scroll');
};
const showAside = (className) => {
    var _a;
    hideAside();
    const aside = document.getElementsByClassName(className);
    window.visibleAside = aside.item(0) || undefined;
    (_a = window.visibleAside) === null || _a === void 0 ? void 0 : _a.classList.add('visible');
    const body = document.querySelector('body');
    body === null || body === void 0 ? void 0 : body.classList.add('no-scroll');
};
const hideMenu = () => {
    var _a;
    (_a = window.visibleMenu) === null || _a === void 0 ? void 0 : _a.classList.remove('visible');
    window.visibleMenu = undefined;
};
const showMenu = (className, event) => {
    var _a;
    event === null || event === void 0 ? void 0 : event.stopPropagation();
    if (window.visibleMenu) {
        return hideMenu();
    }
    const menu = document.getElementsByClassName(className);
    window.visibleMenu = menu.item(0) || undefined;
    (_a = window.visibleMenu) === null || _a === void 0 ? void 0 : _a.classList.add('visible');
};
let isCenteringDisabled = false;
const scrollToProductsCategory = (event) => {
    isCenteringDisabled = true;
    const categories = document.querySelectorAll('.am-category');
    categories.forEach(category => category.classList.remove('selected'));
    const target = event.target;
    if (isHTMLElement(target)) {
        let toCategory = null;
        let parent = target;
        while (parent) {
            toCategory = parent.getAttribute('to-category');
            if (toCategory) {
                break;
            }
            parent = parent.parentElement;
        }
        parent === null || parent === void 0 ? void 0 : parent.classList.add('selected');
        const categoryElement = document.querySelector(`[category="${toCategory}"]`);
        categoryElement === null || categoryElement === void 0 ? void 0 : categoryElement.scrollIntoView({
            behavior: 'smooth',
        });
        setTimeout(() => {
            isCenteringDisabled = false;
        }, 1500);
    }
};
const aknowledgmentCookieName = 'aknowledgment';
const isCookieAknowledged = getCookie(aknowledgmentCookieName);
function setCookie(name) {
    const oneYear = 365 * 24 * 60 * 60 * 1000;
    const d = new Date();
    d.setTime(d.getTime() + oneYear);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = name + '=' + true + ';' + expires + ';path=/';
}
function hideCookieBanner() {
    const cookieBanner = document.querySelector('.cookie-banner-wrap');
    cookieBanner === null || cookieBanner === void 0 ? void 0 : cookieBanner.classList.remove('cookie-popup-visible');
    const merchantOrderButton = document.querySelector('.am-merchant-apps .am-buttons');
    merchantOrderButton === null || merchantOrderButton === void 0 ? void 0 : merchantOrderButton.classList.remove('buttons-moved');
}
function setAknowledgmentCookie() {
    setCookie(aknowledgmentCookieName);
    hideCookieBanner();
}
function showCookieBanner() {
    const cookieBanner = document.querySelector('.cookie-banner-wrap');
    cookieBanner === null || cookieBanner === void 0 ? void 0 : cookieBanner.classList.add('cookie-popup-visible');
    const merchantOrderButton = document.querySelector('.am-merchant-apps .am-buttons');
    merchantOrderButton === null || merchantOrderButton === void 0 ? void 0 : merchantOrderButton.classList.add('buttons-moved');
}
function handleCenterCategory(entries) {
    if (isCenteringDisabled)
        return;
    const entry = entries[0];
    const { category } = entry === null || entry === void 0 ? void 0 : entry.target.attributes;
    const allSelected = document.querySelectorAll('.selected');
    const menuItem = document.querySelector(`[to-category="${category.value}"]`);
    const categoriesBlocks = document.querySelectorAll('[category]');
    const categoriesWrapper = document.querySelector('.am-merchant-categories');
    allSelected.forEach(item => {
        item.classList.remove('selected');
    });
    menuItem === null || menuItem === void 0 ? void 0 : menuItem.classList.add('selected');
    const screenWidth = Math.max(document.documentElement.clientWidth, window.innerWidth);
    const itemLocationX = menuItem === null || menuItem === void 0 ? void 0 : menuItem.getBoundingClientRect().x;
    if (navigator.userAgentData.mobile && // there are no types for this yet in typescript by default
        entry.target !== categoriesBlocks[0] &&
        entry.isIntersecting &&
        itemLocationX &&
        (itemLocationX + 70 > screenWidth || itemLocationX < 70) // checking if menu item is slightly/completely outside viewport
    ) {
        categoriesWrapper === null || categoriesWrapper === void 0 ? void 0 : categoriesWrapper.scrollTo({
            behavior: 'smooth',
            left: itemLocationX,
        });
    }
}
function startWathingCategories() {
    const categoriesBlocks = document.querySelectorAll('[category]');
    categoriesBlocks.forEach(element => {
        const observer = new IntersectionObserver(handleCenterCategory, {
            rootMargin: '-40% 0px -60% 0px',
            threshold: 0,
        });
        observer.observe(element);
    });
}
function handleCrossMenu() {
    const menu = document.querySelector('ul.am-merchant-categories');
    const action = document.querySelector('.am-merchant-actions.am-buttons');
    if (!menu || !action)
        return;
    const hasClass = action.classList.value.includes('actions-moved');
    const actionsRect = action.getBoundingClientRect();
    const menuRect = menu.getBoundingClientRect();
    if (actionsRect.bottom - 10 > menuRect.top && !hasClass) {
        action.classList.add('actions-moved');
        return;
    }
    if (actionsRect.top < menuRect.bottom && hasClass) {
        action.classList.remove('actions-moved');
    }
}
function watchMerchantBackButton() {
    const actions = document.querySelector('.am-merchant-actions.am-buttons');
    const menu = document.querySelector('ul.am-merchant-categories');
    if (!actions || !menu)
        return;
    window.addEventListener('scroll', handleCrossMenu);
}
function handleScrollLocations(e) {
    var _a;
    const element = e.target;
    const hintElement = (_a = element.closest('.dropdown-menu-main')) === null || _a === void 0 ? void 0 : _a.querySelector('.scroll-for-more');
    if (Math.floor(element.scrollHeight - element.scrollTop) <= element.clientHeight) {
        hintElement === null || hintElement === void 0 ? void 0 : hintElement.classList.add('tranparent');
    }
    else if (hintElement === null || hintElement === void 0 ? void 0 : hintElement.classList.contains('tranparent')) {
        hintElement === null || hintElement === void 0 ? void 0 : hintElement.classList.remove('tranparent');
    }
}
function startWatchLocationDropdownScroll() {
    const dropdowns = document.querySelectorAll('.am-dropdown-menu.header-block-menu .dropdown-menu-main .am-dropdown-items');
    dropdowns.forEach(item => {
        item.addEventListener('scroll', handleScrollLocations);
    });
}
const onLoad = () => {
    // @ts-ignore
    window.mainBanner = new Swiper('.swiper-main', {
        loop: true,
        autoplay: {
            delay: 4000,
            pauseOnMouseEnter: true,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        createElements: true,
    });
    // @ts-ignore not sure how to properly set types here
    window.aboutUsSlider = new Swiper('.swiper', { loop: true });
    window.faqAccordion = new Accordion('am-faq-accordion');
    startWathingCategories();
    watchMerchantBackButton();
    startWatchLocationDropdownScroll();
    if (!isCookieAknowledged)
        showCookieBanner();
    if (window.location.href.includes(CONTACT_US))
        openContactMeForm();
};
const aboutUsContentLeft = () => {
    var _a;
    (_a = window.aboutUsSlider) === null || _a === void 0 ? void 0 : _a.slidePrev();
};
const aboutUsContentRight = () => {
    var _a;
    (_a = window.aboutUsSlider) === null || _a === void 0 ? void 0 : _a.slideNext();
};
function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
function openContactMeForm(e) {
    if (e) {
        e.preventDefault();
        const { pathname } = window.location;
        const isLastSymbolSlash = pathname[pathname.length - 1] === '/';
        history.replaceState({}, '', `${pathname}${isLastSymbolSlash ? '' : '/'}${CONTACT_US}`);
    }
    const contactFormOverlay = document.querySelector('.contact-me-form-overlay');
    contactFormOverlay === null || contactFormOverlay === void 0 ? void 0 : contactFormOverlay.classList.add('contact-me-form-visible');
}
function closeContactMeForm(e) {
    if (e.target !== e.currentTarget)
        return;
    const contactFormOverlay = document.querySelector('.contact-me-form-overlay');
    contactFormOverlay === null || contactFormOverlay === void 0 ? void 0 : contactFormOverlay.classList.remove('contact-me-form-visible');
    history.replaceState({}, '', window.location.pathname.replace(CONTACT_US, ''));
}
function playHelpPageVideo(e) {
    const video = document.querySelector('.help-page-video');
    if (e.target instanceof HTMLElement) {
        e.target.classList.add('hidden');
    }
    if (video) {
        video.setAttribute('controls', '');
        video.play();
    }
}
function scrollToTop() {
    scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}
window.stopPropagation = stopPropagation;
window.showAside = showAside;
window.hideAside = hideAside;
window.showMenu = showMenu;
window.hideMenu = hideMenu;
window.scrollToProductsCategory = scrollToProductsCategory;
window.scrollToTop = scrollToTop;
window.openContactMeForm = openContactMeForm;
window.closeContactMeForm = closeContactMeForm;
window.aboutUsContentLeft = aboutUsContentLeft;
window.aboutUsContentRight = aboutUsContentRight;
window.onLoad = onLoad;
class Accordion {
    constructor(classNames) {
        this.toggle = (event) => {
            const target = event.target;
            if (!isHTMLElement(target)) {
                return;
            }
            const item = getParent(target, ({ classList }) => classList.contains(Accordion.ITEM));
            if (item) {
                return this.isShowItem(item) ? this.hide(item) : this.show(item);
            }
        };
        const accordion = document.getElementsByClassName(classNames).item(0);
        const items = accordion === null || accordion === void 0 ? void 0 : accordion.getElementsByClassName(Accordion.ITEM);
        this.items = Array.from(items || []);
        this.items.forEach(item => {
            item.addEventListener(Accordion.CLICK, this.toggle);
        });
    }
    show(item) {
        const header = item.getElementsByClassName(Accordion.HEADER).item(0);
        const body = item === null || item === void 0 ? void 0 : item.getElementsByClassName(Accordion.BODY).item(0);
        if (!body || !header) {
            return;
        }
        this.items.filter(({ classList }) => classList.contains(Accordion.SHOW)).forEach(shownItem => this.hide(shownItem));
        item.classList.add(Accordion.SHOW);
    }
    hide(item) {
        const header = item.getElementsByClassName(Accordion.HEADER).item(0);
        const body = item === null || item === void 0 ? void 0 : item.getElementsByClassName(Accordion.BODY).item(0);
        if (!body || !header) {
            return;
        }
        item.classList.remove(Accordion.SHOW);
    }
    isShowItem(item) {
        return item.classList.contains('show');
    }
    [Symbol.dispose]() {
        this.items.forEach(item => item.removeEventListener(Accordion.CLICK, this.toggle));
    }
}
Accordion.ITEM = 'am-accordion-item';
Accordion.HEADER = `${Accordion.ITEM}-header`;
Accordion.BODY = `${Accordion.ITEM}-body`;
Accordion.SHOW = 'show';
Accordion.CLICK = 'click';
